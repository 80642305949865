import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { Link } from 'react-router-dom';
import LinearScaleIcon from '@mui/icons-material/LinearScale';

export function MainListItems() {
  return (
    <>
      <ListItemButton component={Link} to="/">
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Платья" />
      </ListItemButton>
      <ListItemButton component={Link} to="/sale">
        <ListItemIcon>
          <LoyaltyIcon />
        </ListItemIcon>
        <ListItemText primary="Распродажа" />
      </ListItemButton>
      <ListItemButton component={Link} to="/category">
        <ListItemIcon>
          <LinearScaleIcon />
        </ListItemIcon>
        <ListItemText primary="Категории" />
      </ListItemButton>
    </>
  );
}

export default MainListItems;
