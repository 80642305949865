import React from "react";
import { Link } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import { IDressData } from "../../redux/models/IDress";
import { fetchRemoveDress } from "../../redux/slices/dresses";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import axios from "../../axios";

interface Props {
  data: IDressData;
}

export default function Dresses({ data }: Props) {
  const dispatch = useDispatch();
  const onClickRemove = async () => {
    if (window.confirm("Вы действительно хотите удалить платишко?")) {
      try {
        dispatch(fetchRemoveDress(data.id));
        await axios.post(`/removeFile`, {}, { params: { id: `${data.id}` } });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <TableRow
      key={data.title}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell
        component="th"
        scope="row"
      >

        {data.preview && data.preview.length > 0 && (
          // @ts-ignore
          <img width={"100px"} src={`https://api.tanadress.ru/images/${data.preview[0]}`} alt={""} />
        )}
      </TableCell>
      <TableCell align="left">{data.title}</TableCell>
      <TableCell align="left">
        {data.category && data.category.join()}
      </TableCell>
      <TableCell align="left">{data.priceRent}</TableCell>
      <TableCell align="left">{data.priceBuy}</TableCell>
      <TableCell align="left">{data.sort}</TableCell>
      <TableCell
        align="left"
        style={{
          position: "sticky",
          right: 56,
          background: "white",
          zIndex: 800,
        }}
      >
        <Link to={`/dress/${data.id}/edit`}>
          <ModeEditIcon color={"primary"} />
        </Link>
      </TableCell>
      <TableCell
        align="left"
        style={{
          position: "sticky",
          right: 0,
          background: "white",
          zIndex: 800,
        }}
      >
        <HighlightOffIcon onClick={onClickRemove} color={"error"} />
      </TableCell>
    </TableRow>
  );
}
