import React, { useCallback, useEffect, useState } from 'react';

import styles from "./search.module.scss";
import { fetchAllDress } from "../../redux/slices/dresses";
import { useDispatch } from "react-redux";

export interface searchProps {
  title?: string;
}

export default function Search() {
  const dispatch = useDispatch();
  const [searchName, setSearchName] = useState<any>();

  useEffect(() => {
    if(searchName) {
      const delayDebounceFn = setTimeout(() => {

        dispatch(fetchAllDress({ title: searchName, limit: 1000 }));
      }, 2000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [dispatch, searchName]);

  const onchange = useCallback(async (event:any) => {
    if (event.currentTarget.value.length >= 3) {
      setSearchName(event.currentTarget.value)
    }
    if (event.currentTarget.value === "") {
      return null;
    }

  }, []);

  return (
      <input
        className={styles.button}
        onChange={onchange}
        placeholder="Найти любимое платьишко"
      />
  );
}
