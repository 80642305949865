import { configureStore } from '@reduxjs/toolkit';
import dressesSlice from './slices/dresses';
import authSlice from './slices/auth';
import saleSlice from './slices/sale';
import categorySlice from './slices/category'

const store = configureStore({
  reducer: {
    dresses: dressesSlice,
    auth: authSlice,
    sale: saleSlice,
    categoryArray: categorySlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
