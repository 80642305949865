import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
// @ts-ignore
import axios from '../../axios';
import styles from './addSale.module.scss';

export default function SaleDress() {
  const { id } = useParams();
  const isEdited = Boolean(id);
  const [preview, setPreview] = useState<string>('');
  const [gallery, setGallery] = useState<string>('');

  const inputImage = useRef<HTMLInputElement>(null);

  const handleChangeFile = async (currentPathFile:string, event: any) => {
    try {
      const formData = new FormData();
      const file = event?.target.files;

      for (let i = 0; i < file.length; i += 1) {
        formData.append('sale', file[i]);
      }
      const { data } = await axios.post(
        '/uploadSale',
        formData,
        { params: { id: `${id}` } },
      );

      return setPreview(data.data.filename);

    } catch (err) {
      console.log(err);
    }
  };

  const onClickRemoveImage = async (event: any) => {
    try {
      // const formData = new FormData();
    const nameImage = event.target.nextSibling.dataset.name;
    await axios.post(`/removeFile`, {currentPathFile: nameImage})
    setPreview('');
    setGallery('');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      axios.get(`/sale/${id}`).then((data: any) => {
        setPreview(data.data.preview);
        setGallery(data.data.gallery);
      });
    }
  }, [id]);


  const navigate = useNavigate();
  const onsubmit = async () => {
    try {
      const fields = {
        preview,
        gallery,
      };
      const { data } = isEdited
        ? await axios.patch(`/sale/${id}`, fields)
        : await axios.post('/sale', fields);
      const idDress = isEdited ? id : data.id;
      const url = isEdited ? '/sale' : `/sale/${idDress}/edit`;
      navigate(`${url}`);
    } catch (err) {
      console.log('Не удалось отправить');
    }
  };

  return (
    <>
        {isEdited && (
          <>
          <div>
            <div className={styles.Item}>
              <Button
                onClick={() => inputImage.current?.click()}
                variant="outlined"
                size="large"
              >
                Загрузить превью
              </Button>
              <input
                ref={inputImage}
                type="file"
                onChange={(e) => handleChangeFile('preview', e)}
                hidden
              />
            </div>
            <div className={styles.imageContainer}>
            {preview
              &&
                <div className={styles.imageItem}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={onClickRemoveImage}
                    className={styles.imageDelete}
                  >
                    Удалить
                  </Button>
                  <img
                    data-name={preview}
                    className={styles.image}
                    src={`https://api.tanadress.ru/sale/${id}/resized/${preview}`}
                    alt="Uploaded"
                  />
                </div>
              }
            </div>
          </div>
          </>
        )}
        <br />
        <br />
        <div className={styles.buttons}>
          <Button onClick={onsubmit} size="large" variant="contained">
            {isEdited ? 'Сохранить' : 'Опубликовать'}
          </Button>
          <a href="/sale">
            <Button size="large">Отмена</Button>
          </a>
        </div>
    </>
  );
}
