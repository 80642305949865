import React from 'react';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useDispatch } from 'react-redux';
import { fetchRemoveDressSale } from '../../redux/slices/sale';
import axios from '../../axios';
import { ISaleData } from '../../redux/models/ISale';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
  data: ISaleData;
}

export default function SaleDress({ data }: Props) {
  const dispatch = useDispatch();
  const onClickRemove = async () => {
    if (window.confirm('Вы действительно хотите удалить платишко?')) {
      try {
        dispatch(fetchRemoveDressSale(data.id));
        await axios.post(`/removeFileSale`, {}, { params: { id: `${data.id}` } },);
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <TableRow
      key={data.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell align="left"><img loading={'lazy'} style={{width: '50px', height: '100px'}} src={`https://api.tanadress.ru/sale/${data.id}/resized/${data.preview}`} alt={''}/></TableCell>
      <TableCell style={{width: '1%'}} align="left">
        <Link to={`/sale/${data.id}/edit`}>
          <ModeEditIcon   color={'primary'}  />
        </Link>
      </TableCell>
      <TableCell style={{width: '1%'}} align="left">
        <HighlightOffIcon onClick={onClickRemove} color={'error'} />
      </TableCell>
    </TableRow>
  );
}
