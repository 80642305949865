import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { ICategory } from '../models/ICategory';

export interface CategoryState {
  categoryArray: ICategory;
}

export const fetchAllCategory: any = createAsyncThunk<any>(
  'categoryArray/fetchCategory',
  async () => {
    const { data }: any = await axios.get('/category');
    return data;
  },
);

export const fetchRemoveCategory: any = createAsyncThunk<any>(
  'categoryArray/fetchRemoveCategory',
  async (id) => {
    axios.delete(`/category/${id}`);
  },
);

const initialState: CategoryState = {
  categoryArray: {
    items: [],
    status: 'loading',
  },
};

const categorySlice = createSlice({
  name: 'categoryArray',
  initialState,
  reducers: {},
  extraReducers: {
    // Получение платьев с распродажи
    [fetchAllCategory.pending]: (state) => {
      state.categoryArray.items = [];
      state.categoryArray.status = 'loading';
    },
    [fetchAllCategory.fulfilled]: (state, action) => {
      state.categoryArray.items = action.payload.data;
      state.categoryArray.status = 'loaded';
    },
    [fetchAllCategory.rejected]: (state) => {
      state.categoryArray.items = [];
      state.categoryArray.status = 'error';
    },
    // Удаление Платья с распродажи
    [fetchRemoveCategory.pending]: (state, action) => {
      state.categoryArray.items = state.categoryArray.items
        && state.categoryArray.items.filter((items) => items.id !== action.meta.arg);
    },
  },
});

export default categorySlice.reducer;
