import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CategoryItem from '../components/category'

import {
  CategoryState,
  fetchAllCategory,
} from '../redux/slices/category';
import { ICategoryData } from '../redux/models/ICategory';

export default function Category() {
  const dispatch = useDispatch();

  const { categoryArray }: any = useSelector((state: CategoryState) => state.categoryArray);

  const isDressesLoading = categoryArray.status === 'loading';

  useEffect(() => {
    dispatch(fetchAllCategory());
  }, [dispatch]);

  if(isDressesLoading) {
    return <span>Загрузка...</span>
  }

  return (
    <>
      <Box display="flex">
        <Button
          component={Link}
          color="secondary"
          to="/add-category"
          variant="contained"
          size="large"
          sx={{ mb: 2 }}
        >
          Добавить категорию
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="left">Название</TableCell>
              <TableCell align="left">Url категории</TableCell>
              <TableCell align="left">Сортировка</TableCell>
              <TableCell align="left">Подборка</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryArray?.items.map((item: ICategoryData) => (
              <CategoryItem key={item.id} data={item} />
            ))}
          </TableBody>

        </Table>
      </TableContainer>
    </>
  );
}
