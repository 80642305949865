import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Home from './pages/Home';
import AddDress from './pages/AddDress';
import AddSale from './pages/AddSale';
import AddCategory from './pages/AddCategory';
import Login from './pages/Login';
import { fetchAuthMe, selectIsAuth } from './redux/slices/auth';
import Layout from './components/layout';
import AddDressListing from './pages/Sale';
import AddCategoryListing from './pages/Category'
function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const { status } = useSelector((state: any) => state.auth);

  const isisAuthLoading = status === 'loading';

  useEffect(() => {
    dispatch(fetchAuthMe());
  }, [dispatch]);

  if (isisAuthLoading) {
    return (
      <div className="loader-container">
        <div className="spinner" />
      </div>
    );
  }

  if (!isAuth) {
    return <Login />;
  }

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dress/:id/edit" element={<AddDress />} />
          <Route path="/login" element={<Login />} />
          <Route path="/add-dress" element={<AddDress />} />
          <Route path="/sale" element={<AddDressListing />} />
          <Route path="/sale/:id/edit" element={<AddSale />} />
          <Route path="/add-sale" element={<AddSale />} />
          <Route path="/category/:id/edit" element={<AddCategory />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/category" element={<AddCategoryListing />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
