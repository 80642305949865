import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { ISale } from '../models/ISale';

export interface SaleState {
  sale: ISale;
}

export const fetchAllDressSale: any = createAsyncThunk<any>(
  'dresses/fetchDresses',
  async () => {
    const { data }: any = await axios.get('/sale');
    return data;
  },
);

export const fetchRemoveDressSale: any = createAsyncThunk<any>(
  'dresses/fetchRemoveDresses',
  async (id) => {
    axios.delete(`/sale/${id}`);
  },
);

const initialState: SaleState = {
  sale: {
    items: [],
    status: 'loading',
  },
};

const saleSlice = createSlice({
  name: 'dresses',
  initialState,
  reducers: {},
  extraReducers: {
    // Получение платьев с распродажи
    [fetchAllDressSale.pending]: (state) => {
      state.sale.items = [];
      state.sale.status = 'loading';
    },
    [fetchAllDressSale.fulfilled]: (state, action) => {
      state.sale.items = action.payload.data;
      state.sale.status = 'loaded';
    },
    [fetchAllDressSale.rejected]: (state) => {
      state.sale.items = [];
      state.sale.status = 'error';
    },
    // Удаление Платья с распродажи
    [fetchRemoveDressSale.pending]: (state, action) => {
      state.sale.items = state.sale.items
        && state.sale.items.filter((items) => items.id !== action.meta.arg);
    },
  },
});

export default saleSlice.reducer;
