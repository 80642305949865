import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
// @ts-ignore
import axios from '../../axios';
import styles from './addCategory.module.scss';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel } from '@mui/material';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor'

export default function AddCategory() {
  const { id } = useParams();
  const isEdited = Boolean(id);
  const [title, setTitle] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [description, setDescription] = useState('');
  const [selections, setSelections] = useState<boolean>(false);
  const [sort, setSort] = useState<number>();


  useEffect(() => {
    if (id) {
      axios.get(`/category/${id}`).then((data: any) => {
        setTitle(data.data.title);
        setSlug(data.data.slug);
        setDescription(data.data.description);
        setSelections(data.data.selections);
        setSort(data.data.sort);
      });
    }
  }, [id]);

  const handleChangeCheckBox = (event: any) => {
    setSelections(event.target.checked);
  };

  const navigate = useNavigate();
  const onsubmit = async () => {
    try {
      const fields = {
        title,
        slug,
        selections,
        description,
        sort,
      };
      const { } = isEdited
        ? await axios.patch(`/category/${id}`, fields)
        : await axios.post('/category', fields);
      navigate(`/category`);
    } catch (err) {
      console.log('Не удалось отправить');
    }
  };

  return (
    <>
      <TextField
        classes={{ root: styles.title }}
        variant="standard"
        placeholder="Название категории..."
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        classes={{ root: styles.tags }}
        variant="standard"
        placeholder="Урл на иностранном языке"
        fullWidth
        value={slug}
        onChange={(e) => setSlug(e.target.value)}
      />
      <div style={{margin: '40px 0 40px 0'}}>
        <CKEditor
          editor={Editor}
          data={description}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            setDescription(data);
          }}
        />
      </div>
      <TextField
        classes={{ root: styles.tags }}
        variant="standard"
        placeholder="Сортировка"
        fullWidth
        type="number"
        value={sort}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => setSort(parseInt(e.target.value, 10))}
      />
      <div className={styles.Item}>
        <FormControlLabel
          control={
            <Checkbox checked={selections} onChange={handleChangeCheckBox} />
          }
          label="Является ли подборкой?"
        />
      </div>
      <div className={styles.buttons}>
        <Button onClick={onsubmit} size="large" variant="contained">
          {isEdited ? 'Сохранить' : 'Опубликовать'}
        </Button>
        <a href="/">
          <Button size="large">Отмена</Button>
        </a>
      </div>
    </>
  );
}
