import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SaleDress from '../components/sale'

import {
  SaleState,
  fetchAllDressSale,
} from '../redux/slices/sale';
import { ISaleData } from '../redux/models/ISale';

export default function Sale() {
  const dispatch = useDispatch();

  const { sale }: any = useSelector((state: SaleState) => state.sale);

  const isDressesLoading = sale.status === 'loading';

  useEffect(() => {
    dispatch(fetchAllDressSale());
  }, [dispatch]);

  if(isDressesLoading) {
    return <span>Загрузка...</span>
  }

  return (
    <>
      <Box display="flex">
        <Button
          component={Link}
          color="secondary"
          to="/add-sale"
          variant="contained"
          size="large"
          sx={{ mb: 2 }}
        >
          Добавить платишко
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="left">Фотография</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sale?.items.map((item: ISaleData) => (
              <SaleDress key={item.id} data={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
