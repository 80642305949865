import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuth, logout } from '../../redux/slices/auth';
import LogoutIcon from '@mui/icons-material/Logout';
export function Header() {
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const onClickLogOut = () => {
    dispatch(logout());
    window.localStorage.removeItem('token');
  };
  return (
    <>
      {isAuth && (
        <LogoutIcon onClick={onClickLogOut} />
      )}
    </>
  );
}

export default Header;
