import React from 'react';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useDispatch } from 'react-redux';
import { fetchRemoveCategory } from '../../redux/slices/category';
import { ICategoryData } from '../../redux/models/ICategory';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface Props {
  data: ICategoryData ;
}

export default function CategoryItem({ data }: Props) {
  const dispatch = useDispatch();
  const onClickRemove = async () => {
    if (window.confirm('Вы действительно хотите удалить категорию?')) {
      try {
        dispatch(fetchRemoveCategory(data.id));
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <TableRow
      key={data.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell align="left">
        {data.title}
      </TableCell>
      <TableCell align="left">
        {data.slug}
      </TableCell>
      <TableCell align="left">{data.sort}</TableCell>
      <TableCell align="left">{data.selections ? <VisibilityIcon color={'primary'} /> : <VisibilityOffIcon color={'error'} />}</TableCell>
      <TableCell style={{width: '1%'}} align="left">
        <Link to={`/category/${data.id}/edit`}>
          <ModeEditIcon   color={'primary'}  />
        </Link>
      </TableCell>
      <TableCell style={{width: '1%'}} align="left">
        <HighlightOffIcon onClick={onClickRemove} color={'error'} />
      </TableCell>
    </TableRow>
  );
}
