import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Checkbox, FormControlLabel, ListItemText } from "@mui/material";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
import axios from "../../axios";
import styles from "./addDress.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { CategoryState, fetchAllCategory } from "../../redux/slices/category";
import { ICategoryData } from "../../redux/models/ICategory";
import Box from '@mui/material/Box';

export default function AddDress() {
  const { id } = useParams();
  const isEdited = Boolean(id);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [preview, setPreview] = useState<string[]>([]);
  const [gallery, setGallery] = useState<string[]>([]);
  const [category, setCategory] = useState<string[]>([]);
  const [priceBuy, setPriceBuy] = useState<string>("");
  const [oldPriceBuy, setOldPriceBuy] = useState<string>("");
  const [priceRent, setPriceRent] = useState<string>("");
  const [pricePhoto, setPricePhoto] = useState<string>("");
  const [sale, setSale] = useState<boolean>(false);
  const [size, setSize] = useState<any>([]);
  const [lengthDress, setLengthDress] = useState<any>([]);
  const [sort, setSort] = useState<number>();
  const [published, setPublished] = useState<boolean>(false);
  const [newDress, setNewDress] = useState<boolean>(false);
  const [byTanaDress, setByTanaDress] = useState<boolean>(false);
  const [colors, setColorsDress] = useState<string[]>([]);
  const [onlyBuy, setOnlyBuy] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { categoryArray }: any = useSelector(
    (state: CategoryState) => state.categoryArray
  );
  useEffect(() => {
    dispatch(fetchAllCategory());
  }, [dispatch]);

  let arrayCategoryNew: string[] = [];
  let arrayCategoryTags: string[] = [];

  categoryArray.items?.map((e: ICategoryData) => {
    if(e.selections === false) {
      arrayCategoryNew.push(e.title);
    } else {
      arrayCategoryTags.push(e.title);
    }
  });


  let arrayDark: string[] = [
    "38",
    "40",
    "42",
    "44",
    "46",
    "48",
    "50",
    "52",
    "54",
    "56",
    "58",
    "60",
  ];
  let arrayLengthDress: string[] = ["Мини", "Миди", "Макси", "Со шлейфом"];

  const colorsArray: string[] = ['Белый', 'Черный', 'Бежевый', 'Золото','Серебро', 'Серый', 'Пудра', 'Розовый', 'Фуксия', 'Красный', 'Бордовый', 'Фиолетовый', 'Лаванда', 'Голубой', 'Синий', 'Изумруд', 'Мята (светло-зеленый)', 'Желтый']

  const inputImage = useRef<HTMLInputElement>(null);
  const inputImageGallery = useRef<HTMLInputElement>(null);

  const handleChangeFile = async (currentPathFile: string, event: any) => {
    try {
      const formData = new FormData();
      const file = event?.target.files;

      for (let i = 0; i < file.length; i += 1) {
        formData.append("images", file[i]);
      }
      const { data } = await axios.post("/upload", formData, {
        params: { id: `${id}`, currentPathFile: `${currentPathFile}` },
      });
      setTimeout(() => {
        if (currentPathFile === "preview") {
          if (preview.length > 0) {
            return setPreview((prev) => [...prev, data.urls.join()]);
          }

          return setPreview(data.urls);
        } else {
          if (gallery.length > 0) {
            return setGallery(gallery.concat(data.urls));
          }

          return setGallery(data.urls);
        }
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickRemoveImage = async (event: any) => {
    try {
      const nameImage = event.target.nextSibling.dataset.name;
      const deleteImage = preview.filter((e) => e !== nameImage);
      const deleteImageGallery = gallery.filter((e) => e !== nameImage);

      await axios.post(`/removeFile`, { currentPathFile: nameImage });
      setPreview(deleteImage);
      setGallery(deleteImageGallery);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      axios.get(`/dress/${id}`).then((data: any) => {
        setTitle(data.data.title);
        setPriceBuy(data.data.priceBuy);
        setPriceRent(data.data.priceRent);
        setCategory(data.data.category);
        setSize(data.data.size);
        setSort(data.data.sort);
        setDescription(data.data.description);
        setSale(data.data.sale);
        setPreview(data.data.preview);
        setPublished(data.data.published);
        setGallery(data.data.gallery);
        setOldPriceBuy(data.data.oldPriceBuy);
        setNewDress(data.data.newDress);
        setLengthDress(data.data.lengthDress)
        setByTanaDress(data.data.byTanaDress)
        setColorsDress(data.data.colors)
        setOnlyBuy(data.data.onlyBuy)
      });
    }
  }, [id]);

  const handleChange = (event: SelectChangeEvent<typeof category>) => {
    const {
      target: { value },
    } = event;
    setCategory(
      // On autofill, we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeDark = (event: SelectChangeEvent<typeof size>) => {
    const {
      target: { value },
    } = event;
    setSize(
      // On autofill, we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeLengthDress = (event: SelectChangeEvent<typeof size>) => {
    const {
      target: { value },
    } = event;
    setLengthDress(
      // On autofill, we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeColorsDress = (event: SelectChangeEvent<typeof size>) => {
    const {
      target: { value },
    } = event;
    setColorsDress(
      // On autofill, we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };


  const handleChangeCheckBox = (event: any) => {
    setPublished(event.target.checked);
  };

  const handleChangeCheckBoxNewDress = (event: any) => {
    setNewDress(event.target.checked);
  };

  const handleChangeCheckBoxByTanaDress = (event: any) => {
    setByTanaDress(event.target.checked);
  };

  const handleChangeCheckBuyOnly = (event: any) => {
    setOnlyBuy(event.target.checked);
  };

  const handleChangeSale = (event: any) => {
    setSale(event.target.checked);
  };

  const navigate = useNavigate();
  const onsubmit = async () => {
    try {
      const fields = {
        title,
        category,
        description,
        size,
        sort,
        sale,
        priceBuy,
        priceRent,
        preview,
        published,
        gallery,
        oldPriceBuy,
        newDress,
        lengthDress,
        byTanaDress,
        colors,
        onlyBuy,
      };
      const { data } = isEdited
        ? await axios.patch(`/dress/${id}`, fields)
        : await axios.post("/dress", fields);
      const idDress = isEdited ? id : data.id;
      const url = isEdited ? "/" : `/dress/${idDress}/edit`;
      navigate(`${url}`);
    } catch (err) {
      console.log("Не удалось отправить");
    }
  };

  return (
    <>
      {isEdited && (
        <>
          <div>
            <div className={styles.imageContainer}>
              {preview &&
                preview.map((el) => (
                  <div className={styles.imageItem} key={el}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={onClickRemoveImage}
                      className={styles.imageDelete}
                    >
                      Удалить
                    </Button>
                    <img
                      data-name={el}
                      className={styles.image}
                      src={`https://api.tanadress.ru/images/${el}`}
                      alt="Uploaded"
                    />
                  </div>
                ))}
            </div>
            <div className={styles.Item}>
              <Button
                onClick={() => inputImage.current?.click()}
                variant="outlined"
                size="large"
              >
                Загрузить превью
              </Button>
              <input
                ref={inputImage}
                multiple
                type="file"
                onChange={(e) => handleChangeFile("preview", e)}
                hidden
              />
            </div>
          </div>
          <div>
            <div className={styles.imageContainer}>
              {gallery &&
                gallery.map((el) => (
                  <div className={styles.imageItem} key={el}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={onClickRemoveImage}
                      className={styles.imageDelete}
                    >
                      Удалить
                    </Button>
                    <img
                      data-name={el}
                      className={styles.image}
                      src={`https://api.tanadress.ru/images/${el}`}
                      alt="Uploaded"
                    />
                  </div>
                ))}
            </div>
            <div className={styles.Item}>
              <Button
                onClick={() => inputImageGallery.current?.click()}
                variant="outlined"
                size="large"
              >
                Загрузить фото в галерею
              </Button>
              <input
                ref={inputImageGallery}
                multiple
                type="file"
                onChange={(e) => handleChangeFile("gallery", e)}
                hidden
              />
            </div>
          </div>
        </>
      )}
      <TextField
        classes={{ root: styles.title }}
        variant="standard"
        placeholder="Название платья..."
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        classes={{ root: styles.tags }}
        variant="standard"
        placeholder="Цена покупки"
        fullWidth
        value={priceBuy}
        onChange={(e) => setPriceBuy(e.target.value)}
      />
      <TextField
        classes={{ root: styles.tags }}
        variant="standard"
        placeholder="Скидка на покупку"
        fullWidth
        value={oldPriceBuy}
        onChange={(e) => setOldPriceBuy(e.target.value)}
      />
      <TextField
        classes={{ root: styles.tags }}
        variant="standard"
        placeholder="Цена аренды"
        fullWidth
        value={priceRent}
        onChange={(e) => setPriceRent(e.target.value)}
      />
      <TextField
        classes={{ root: styles.tags }}
        variant="standard"
        placeholder="Цена фотосессии"
        fullWidth
        value={pricePhoto}
        onChange={(e) => setPricePhoto(e.target.value)}
      />
      <div className={styles.select}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">
            Выберите категорию
          </InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            multiple
            value={category}
            onChange={handleChange}
            input={<OutlinedInput label="Категории" />}
            renderValue={(selected) => {
              if (selected.length < 1) {
                return <em>Выберите категорию</em>;
              }
              return selected.join(", ");
            }}
          >
            {arrayCategoryNew.map((name, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={`${name} - ${index}`} value={name}>
                <Checkbox checked={category.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ display: "flex", marginBottom: '20px' }}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">
            Выберите подборки
          </InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            multiple
            value={category}
            onChange={handleChange}
            input={<OutlinedInput label="Подборки" />}
            renderValue={(selected) => {
              if (selected.length < 1) {
                return <em>Выберите Подборку</em>;
              }
              return selected.join(", ");
            }}
          >
            {arrayCategoryTags.map((name, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={`${name} - ${index}`} value={name}>
                <Checkbox checked={category.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ display: "flex" }}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Выберите размер</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            multiple
            value={size}
            onChange={handleChangeDark}
            input={<OutlinedInput label="Размеры" />}
            renderValue={(selected) => {
              if (selected.length < 1) {
                return <em>размеры</em>;
              }
              return selected.join(", ");
            }}
          >
            {arrayDark.map((name, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={`${name} - ${index}`} value={name}>
                <Checkbox checked={size.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Выберите длину платья</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            multiple
            value={lengthDress}
            onChange={handleChangeLengthDress}
            input={<OutlinedInput label="Длина" />}
            renderValue={(selected) => {
              if (selected.length < 1) {
                return <em>Длина</em>;
              }
              return selected.join(", ");
            }}
          >
            {arrayLengthDress.map((name, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={`${name} - ${index}`} value={name}>
                <Checkbox checked={lengthDress.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TextField
        classes={{ root: styles.tags }}
        variant="standard"
        placeholder="Длина платья"
        fullWidth
        value={lengthDress}
        onChange={(e) => {
          setLengthDress([]);
        }}
      />
      <div style={{ display: "flex", marginTop: "20px" }}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Выберите цвет платья</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            multiple
            value={colors}
            onChange={handleChangeColorsDress}
            input={<OutlinedInput label="Цвет" />}
            renderValue={(selected) => {
              if (selected.length < 1) {
                return <em>Цвет</em>;
              }
              return selected.join(", ");
            }}
          >
            {colorsArray.map((name, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={`${name} - ${index}`} value={name}>
                <Checkbox checked={colors.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ margin: "40px 0 40px 0" }}>
        <CKEditor
          editor={Editor}
          data={description}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            setDescription(data);
          }}
        />
      </div>
      <TextField
        classes={{ root: styles.tags }}
        variant="standard"
        placeholder="Сортировка"
        fullWidth
        type="number"
        value={sort}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => setSort(parseInt(e.target.value, 10))}
      />
      <Box display='flex' sx={{
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}>
        <div className={styles.Item}>
          <FormControlLabel
            control={
              <Checkbox
                checked={newDress}
                onChange={handleChangeCheckBoxNewDress}
              />
            }
            label='Новое платье'
          />
        </div>
        <div className={styles.Item}>
          <FormControlLabel
            control={
              <Checkbox
                checked={byTanaDress}
                onChange={handleChangeCheckBoxByTanaDress}
              />
            }
            label='Собственное производство'
          />
        </div>
        <div className={styles.Item}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sale}
                onChange={handleChangeSale}
              />
            }
            label='Распродажа'
          />
        </div>
        <div className={styles.Item}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyBuy}
                onChange={handleChangeCheckBuyOnly}
              />
            }
            label='Только покупка'
          />
        </div>
      </Box>
      <div className={styles.Item}>
        <FormControlLabel
          control={
            <Checkbox checked={published} onChange={handleChangeCheckBox} />
          }
          label='Опубликовать?'
        />
      </div>
      <div className={styles.buttons}>
        <Button onClick={onsubmit} size="large" variant="contained">
          {isEdited ? "Сохранить" : "Опубликовать"}
        </Button>
        <a href="/">
          <Button size="large">Отмена</Button>
        </a>
      </div>
    </>
  );
}
