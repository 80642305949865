import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

const initialState = {
  data: null,
  status: 'loading',
};

export const fetchAuth: any = createAsyncThunk(
  'auth/fetchUserData',
  async (params) => {
    const { data }: any = await axios.post('/login', params);
    return data;
  },
);

export const fetchAuthMe: any = createAsyncThunk(
  'auth/fetchAuthMe',
  async () => {
    const { data }: any = await axios.get('/auth/me');
    return data;
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.data = null;
    },
  },
  extraReducers: {
    [fetchAuth.pending]: (state) => {
      state.status = 'loading';
      state.data = null;
    },
    [fetchAuth.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'loaded';
    },
    [fetchAuth.rejected]: (state) => {
      state.data = null;
      state.status = 'error';
    },
    [fetchAuthMe.pending]: (state) => {
      state.status = 'loading';
      state.data = null;
    },
    [fetchAuthMe.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'loaded';
    },
    [fetchAuthMe.rejected]: (state) => {
      state.data = null;
      state.status = 'error';
    },
  },
});

export const selectIsAuth = (state: any) => Boolean(state.auth.data);
export const { logout } = authSlice.actions;
export default authSlice.reducer;
